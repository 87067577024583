/* global django */
import React from 'react';

export default function nl2br(text) {
    const res = [];
    text.split('\n').forEach((x, i) => {
        if (i !== 0) {
            res.push(<br key={`br-${i}`} />); // eslint-disable-line react/no-array-index-key
        }

        res.push(x);
    });

    return res;
}

/* The following are global functions that get injected from Django. They allow for translations in JavaScript
   to make their way to .po files.
 */
export function gettext(value) {
    return django.gettext(value);
}

export function pgettext(context, value) {
    return django.pgettext(context, value);
}

export function ngettext(singular, plural, objectCount) {
    return django.ngettext(singular, plural, objectCount);
}

export function interpolate(formats, counts, kwargs) {
    return django.interpolate(formats, counts, kwargs);
}
