import { SET_IS_ONLINE, SET_MEASURED_PIPE } from '../actions/types';

// eslint-disable-next-line
function isOnline(state = true, action) {
    switch (action.type) {
        case SET_IS_ONLINE:
            return action.isOnline;
        default:
            return state;
    }
}

function measuredPipe(state = null, action) {
    switch (action.type) {
        case SET_MEASURED_PIPE:
            return action.pipe;
        default:
            return state;
    }
}

function measuredPipeError(state = null, action) {
    switch (action.type) {
        case SET_MEASURED_PIPE:
            return action.error;
        default:
            return state;
    }
}

export { isOnline, measuredPipe, measuredPipeError };
